// hooks/useDeckColumns.ts
import { getActions, getGlobal } from '../../../../global';
import { useCallback, useEffect, useState } from '../../../../lib/teact/teact';

export interface Deck {
    name: string;
    chatIds: { chatId: string, topicId?: string }[];
}

export type UserDeckData = {
    userId: string,
    selectedDeck: string,
    decks: Deck[]
}

interface UserSettingsFromDb {
    decks: Deck[];
    selectedDeck: string;
}

interface UserDataFromDb {
    chat_id: string;
    allowed_decks: number;
    allowed_columns: number;
    subscription_expiry: string;
    subscribed_plan: string;
    user_settings: UserSettingsFromDb;
    created_at: string;
}

type UserDataResponseFromDb = UserDataFromDb[];

export const initialUserDeckState: UserDeckData = {
    userId: '1234',
    selectedDeck: 'Deck 1',
    decks: [{
        name: 'Deck 1',
        chatIds: [],
    }]
}

const SUPABASE_URL = 'https://ytuifimjlwecukvvhwlv.supabase.co';
const SUPABASE_ANON_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inl0dWlmaW1qbHdlY3VrdnZod2x2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDM1MDg3ODAsImV4cCI6MjA1OTA4NDc4MH0.wMSD7tc1o7Z1DQrTSeIxYE_rjxEGgfcruPvRoqC1erQ';

// hooks/useDeckColumns.ts

export function useDeckColumns() {
    const global = getGlobal();
    const currentUserId = global.currentUserId;

    console.log({ userId: global.currentUserId }, '-------global.currentUserId')
    const { showNotification } = getActions();
    // Add loading state to prevent race conditions
    const [isLoading, setIsLoading] = useState(true);
    const [userDeckData, setUserDeckData] = useState<UserDeckData>({
        userId: currentUserId || '1234',
        selectedDeck: 'Deck 1',
        decks: [{
            name: 'Deck 1',
            chatIds: [],
        }]
    });

    const [userDataFromDb, setUserDataFromDb] = useState<UserDataFromDb>()

    // Load data only once on initial mount
    useEffect(() => {
        let isMounted = true;

        async function fetchUserData() {
            if (!currentUserId) {
                setIsLoading(false);
                return
            }
            try {
                setIsLoading(true);
                const data = await getInitialUserDataFromSupabase(currentUserId);


                // Only update state if component is still mounted
                if (isMounted && data) {

                    setUserDeckData(data);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            } finally {
                if (isMounted) setIsLoading(false);
            }
        }

        fetchUserData();

        // Cleanup function to prevent state updates after unmount
        return () => {
            isMounted = false;
        };
    }, [currentUserId]); // Only depend on userId

    // Memoize all callback functions to prevent recreating them on every render
    const updateSettingsToSupabase = useCallback(async (newData: UserDeckData) => {
        try {
            let response = await fetch(`${SUPABASE_URL}/rest/v1/users?chat_id=eq.${newData.userId}`, {
                method: 'PATCH',
                headers: {
                    'apikey': SUPABASE_ANON_KEY,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    user_settings: {
                        decks: newData.decks,
                        selectedDeck: newData.selectedDeck
                    }
                }),
            });

            if (!response.ok) {
                throw new Error(`Failed to update user settings: ${await response.text()}`);
            }
        } catch (error) {
            console.error('Failed to update settings:', error);
        }
    }, []);

    // Only update Supabase when data changes and is valid
    useEffect(() => {
        if (!userDeckData.decks.length) {
            setUserDeckData(initialUserDeckState)
        }
        if (!userDeckData.decks.find(item => item.name === userDeckData.selectedDeck)) {
            let temp = userDeckData
            temp.selectedDeck == userDeckData.decks[0].name
            setUserDeckData(temp)
        }
        if (!isLoading && userDeckData.decks.length > 0) {
            const hasRealData = userDeckData.decks.some(deck => deck.chatIds.length > 0) ||
                userDeckData.decks.length > 1;

            if (hasRealData) {

                updateSettingsToSupabase(userDeckData);
            }
        }
    }, [userDeckData, isLoading, updateSettingsToSupabase]);

    // Safe state updaters that preserve previous state
    const addDeck = useCallback((name: string) => {

        console.log({ userDataFromDb, }, userDeckData.decks.length, userDataFromDb?.allowed_decks, 'userdata add deck')
        if (userDataFromDb && userDeckData.decks.length >= userDataFromDb?.allowed_decks) {
            showNotification({
                message: 'Max Number of Decks Reached',
            });
        } else {
            setUserDeckData(prev => ({
                ...prev,
                decks: [...prev.decks, { name, chatIds: [] }]
            }));
        }

    }, [userDataFromDb, userDeckData]);

    const removeDeck = useCallback((name: string) => {
        setUserDeckData(prev => ({
            ...prev,
            decks: prev.decks.filter((deck) => deck.name !== name)
        }));
    }, []);

    const addChatToDeck = useCallback((deckName: string, chatId: string, topicId?: string) => {
        console.log({ userDataFromDb, }, userDeckData.decks.find(item => item.name == deckName), userDataFromDb?.allowed_columns, 'userdata add chat')

        if (userDataFromDb && userDeckData.decks.find(item => item.name == deckName)!.chatIds.length >= userDataFromDb?.allowed_columns) {
            showNotification({
                message: 'Max Number of colums in a deck reached!',
            });
        } else {
            setUserDeckData(prev => ({
                ...prev,
                decks: prev.decks.map((deck) => {
                    if (deck.name === deckName) {
                        return {
                            ...deck,
                            chatIds: [...deck.chatIds, { chatId, topicId }],
                        };
                    }
                    return deck;
                })
            }));
        }



    }, [userDataFromDb, userDeckData]);

    const renameDeck = useCallback((deckName: string, newName: string) => {
        setUserDeckData(prev => ({
            ...prev,
            decks: prev.decks.map((deck) => {
                if (deck.name === deckName) {
                    return {
                        ...deck,
                        name: newName
                    };
                }
                return deck;
            })
        }));
    }, []);

    const setSelectedDeck = useCallback((deckName: string) => {
        setUserDeckData(prev => {
            if (prev.decks.find(item => item.name === deckName)) {
                return {
                    ...prev,
                    selectedDeck: deckName
                };
            }
            return prev;
        });
    }, []);

    const removeChatFromDeck = useCallback((deckName: string, chatId: string) => {
        setUserDeckData(prev => ({
            ...prev,
            decks: prev.decks.map((deck) => {
                if (deck.name === deckName) {
                    return {
                        ...deck,
                        chatIds: deck.chatIds.filter((chat) => chat.chatId !== chatId),
                    };
                }
                return deck;
            })
        }));
    }, []);

    async function getInitialUserDataFromSupabase(chatId: string) {
        try {
            let response = await fetch(
                `${SUPABASE_URL}/rest/v1/users?chat_id=eq.${chatId}`,
                {
                    headers: {
                        'apikey': SUPABASE_ANON_KEY,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (!response.ok) {
                throw new Error(`Failed to fetch user data: ${await response.text()}`);
            }

            let userDataResp = await response.json();

            if (!userDataResp.length) {
                console.log('User Not Found. Creating New User');

                let responseCreate = await fetch(
                    `${SUPABASE_URL}/rest/v1/users`,
                    {
                        method: 'POST',
                        headers: {
                            'apikey': SUPABASE_ANON_KEY,
                            'Content-Type': 'application/json',

                        },
                        body: JSON.stringify({ chat_id: chatId })
                    }
                );
                const defaultData = {
                    userId: chatId,
                    selectedDeck: 'Deck 1',
                    decks: [{
                        name: 'Deck 1',
                        chatIds: [],
                    }]
                };

                if (!responseCreate.ok) {
                    console.log(`Failed to create user data: ${await responseCreate.text()}`);
                }
                // Create user logic...


                let response = await fetch(
                    `${SUPABASE_URL}/rest/v1/users?chat_id=eq.${chatId}`,
                    {
                        headers: {
                            'apikey': SUPABASE_ANON_KEY,
                            'Content-Type': 'application/json'
                        }
                    }
                );

                if (!response.ok) {
                    console.log(`Failed to fetch created user data: ${await response.text()}`);


                    setUserDataFromDb(

                        {
                            user_settings: {
                                selectedDeck: 'Deck 1',
                                decks: [{
                                    name: 'Deck 1',
                                    chatIds: [] as { chatId: string, topicId?: string }[],
                                }]

                            },
                            chat_id: '1234',
                            allowed_decks: 2,
                            allowed_columns: 3,
                            subscription_expiry: '',
                            subscribed_plan: '',

                            created_at: ''
                        }
                    )

                }


                let userDataResp = await response.json();
                if (userDataResp.length) {
                    const userData = userDataResp[0];
                    console.log(userData, '---userDataFromDb')
                    setUserDataFromDb(userData)
                    return {
                        userId: userData.chat_id,
                        selectedDeck: userData.user_settings.selectedDeck,
                        decks: userData.user_settings.decks
                    };
                }


                return defaultData;
            } else {


                const userData = userDataResp[0];
                console.log(userData, '---userDataFromDb')
                setUserDataFromDb(userData)
                return {
                    userId: userData.chat_id,
                    selectedDeck: userData.user_settings.selectedDeck,
                    decks: userData.user_settings.decks
                };
            }
        } catch (error) {
            console.error('Failed to fetch user data:', error);
            // Return default data on error
            return {
                userId: chatId,
                selectedDeck: 'Deck 1',
                decks: [{
                    name: 'Deck 1',
                    chatIds: [],
                }]
            };
        }
    }

    return {
        isLoading,
        selectedDeck: userDeckData.selectedDeck,
        setSelectedDeck,
        userDeckData,
        addDeck,
        removeDeck,
        addChatToDeck,
        removeChatFromDeck,
        renameDeck
    };
}