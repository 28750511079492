import React from "../../../../lib/teact/teact";

import "./LoadingSpinner.css";

const TelegramFullscreenSpinner = () => {
  return (
    <div className="tg-fullscreen-loader">
      <div className="tg-spinner"></div>
    </div>
  );
};

export default TelegramFullscreenSpinner;
